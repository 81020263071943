<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <Header :headerData={} />

    <div class="panel">

      <H2>AUTHOR GUIDELINES</H2>

      <div class="sect">&nbsp;SECTIONS</div>
      <ol>
        <li><a href="#_1.Submission">Submission</a></li>
        <li><a href="#_2.AIMS AND SCOPE">Aims and Scope</a></li>
        <li><a href="#_3.MANUSCRIPT CATEGORIES AND REQUIREMENTS">Manuscript Categories and Requirements</a></li>
        <li><a href="#_4.DATA PROTECTION">Data Protection</a></li>
        <li><a href="#_5.PREPARING THE SUBMISSION">Preparing the Submission</a></li>
        <li><a href="#_6.EDITORIAL POLICIES AND ETHICAL CONSIDERATIONS">Editorial Policies and Ethical Considerations</a></li>
        <li><a href="#_7.AUTHOR LICENSING AND OPEN ACCESS FEES">Author Licensing and Open Access Fees</a></li>
        <li><a href="#_8.PUBLICATION PROCESS AFTER ACCEPTANCE">Publication Process After Acceptance</a></li>
        <li><a href="#_9.POST PUBLICATION">Post-Publication</a></li>
        <li><a href="#_10.JOURNAL CONTACT DETAILS">Journal Contact Details</a></li>  
      </ol>  

      <div class="sect" id="_1.Submission">&nbsp; 1. SUBMISSION</div>
      <div class="sect-desc">
        <p>Authors should kindly note that submission implies that the content has not been published or submitted for publication elsewhere except as a brief abstract in the proceedings of a scientific meeting or symposium.</p>
        <p>Once the submission materials have been prepared in accordance with the Author Guidelines, manuscripts should be submitted via the journal online editorial system.</p>
        <p>By submitting a manuscript to or reviewing for this publication, your name, email address, and affiliation, and other contact details the publication might require, will be used for the regular operations of the publication, including, when necessary, sharing with the publisher (YSA) and partners for production and publication. The publication and the publisher recognize the importance of protecting the personal information collected from users in the operation of these services, and have practices in place to ensure that steps are taken to maintain the security, integrity, and privacy of the personal data collected and processed. </p>
      </div>

      <div class="sect" id="_2.AIMS AND SCOPE">&nbsp; 2. AIMS AND SCOPE</div>
      <div class="sect-desc">
        <p>YSA was founded by a group of active academic researchers from different fields, including biologists, chemists, physicists, computer scientists,and field ecologists. The association aims at providing STEAM education and chances to primary or secondary school students to conduct scientific experiments with scientists.It is the target of the association to open up student's mind to the vivid world of science; to enlighten students, direct them to raise scientific questions and design experiments; to emphasize the importance of data reproducibility as the core of scientific experiments; to demonstrate how to apply cutting-edge computational techniques such as machine learning, supervised and unsupervised autonomous data-analysis to resolve scientific challenges with novel solutions.</p>
        <p>We welcomes original research submissions from the natural sciences, medical research, engineering, as well as the related social sciences and humanities, including interdisciplinary research.</p>
        <em>Return to Guideline Sections</em>
      </div>

      <div class="sect" id="_3.MANUSCRIPT CATEGORIES AND REQUIREMENTS">&nbsp; 3. MANUSCRIPT CATEGORIES AND REQUIREMENTS</div>
      <div class="sect-desc">
        <p>Types of Papers</p>
        <ul>
          <li><strong>Original Articles – </strong>reports of new research findings or conceptual analyses that make a significant contribution to knowledge. </li>
          <li>Commentaries – evidence-based opinion pieces involving areas of broad interest and invited commentaries.</li>
          <li><strong>Comprehensive Reviews – </strong>critical reviews of the literature, including systematic reviews and meta-analyses. </li>
          <li><strong>Editorial articles – </strong>are usually commissioned but unsolicited material may be considered. Please approach the Editor-in-Chief before submitting this material.</li>
          <li><strong>Methods - </strong>Procedural method in the design and implementation of an experiment or study.</li>
          <li><strong>Short Communication - </strong>Brief observations and research reports in a concise format. Word count 2000 words minimum.</li>
          <li><strong>Technical report - </strong>Extensions or updates to previously published research, reporting additional controls; projects that did not yield publishable results but represent valuable information regarding protocol and data collection; additions to established tools, experimental or computational methods; description of a database; null results and orphan data; data management plans; description of a specific development, technique or procedure, or a modification of an existing technique, procedure or device; new algorithm or computational method, new experimental method, improved version of an experimental protocol or computational approach, new implementation of an existing algorithm. Word Count: 2500 words minimum.</li>          
        </ul>
        <em>Return to Guideline Sections</em>
      </div>

      <div class="sect" id="_4.DATA PROTECTION">&nbsp; 4. DATA PROTECTION</div>
      <div class="sect-desc">
        <p>By submitting a manuscript to or reviewing for this publication, your name, email address, and affiliation, and other contact details the publication might require, will be used for the regular operations of the publication, including, when necessary, sharing with the publisher (YSA) and partners for production and publication. The publication and the publisher recognize the importance of protecting the personal information collected from users in the operation of these services, and have practices in place to ensure that steps are taken to maintain the security, integrity, and privacy of the personal data collected and processed. </p>
      </div>

      <div class="sect" id="_5.PREPARING THE SUBMISSION">&nbsp; 5. PREPARING THE SUBMISSION</div>
      <div class="sect-desc">
        <p><strong>Format Submission</strong></p>
        <p>Young Scientist Association now offers Free Format submission for a simplified and streamlined submission process. Submit your manuscript in whichever format you choose, as long as it is readable and understandable by editors and reviewers. Once accepted pending revisions, use this style guide to adapt your text to ensure your manuscript will be at publication standard and that all information required for a final decision is provided.</p>

        <p><strong>Parts of the Manuscript</strong></p>
        <p>The manuscript should be submitted in separate files: main text file; figures. </p>

        <p><strong>Main Text File</strong></p>
        <p>The text file should be presented in the following order:</p>
        <p>i. A short informative title that contains the major key words. The title should not contain abbreviations;</p>
        <p>ii. A short running title of less than 40 characters;</p>
        <p>iii. The full names of the authors;</p>
        <p>iv. The author's institutional affiliations where the work was conducted, with a footnote for the author’s present address if different from where the work was conducted;</p>
        <p>v. Abstract and keywords;</p>
        <p>vi. Main text;</p>
        <p>vii. Acknowledgments;</p>
        <p>viii. References;</p>
        <p>ix. Tables (each table complete with title and footnotes);</p>
        <p>x. Figure legends;</p>
        <p>xi. Appendices (if relevant).</p>
        <p>Figures and supporting information should be supplied as separate files.</p>

        <p><strong>Authorship</strong></p>
        <p>Please refer to the journal's Authorship policy in the Editorial Policies and Ethical Considerations section for details on author listing eligibility.</p>

        <p><strong>Acknowledgments</strong></p>

        <p>Contributions from anyone who does not meet the criteria for authorship should be listed, with permission from the contributor, in an Acknowledgments section. Financial and material support should also be mentioned. Thanks to anonymous reviewers are not appropriate.</p>

        <p><strong>Conflict of Interest Statement</strong></p>
        <p>Authors will be asked to provide a conflict of interest statement during the submission process. For details on what to include in this section, see the 'Conflict of Interest' section in the Editorial Policies and Ethical Considerations section below. Submitting authors should ensure they liaise with all co-authors to confirm agreement with the final statement.</p>

        <p><strong>Ethical Statements</strong></p>
        <p>An Ethical Statement section is required between the main text and reference list for all FSN submissions. Conflict of interest, ethical review, and informed consent must be addressed. Use the heading “Ethical Statements” for this section. An example is given below.</p>

        <p>Conflict of Interest: The authors declare that they do not have any conflict of interest.</p>

        <p>Ethical Review: “This study does not involve any human or animal testing” or “This study was approved by the Institutional Review Board of XYZ University”.</p>

        <p>Informed Consent: Written informed consent was obtained from all study participants.</p>

        <p><strong>Abstract</strong></p>
        <p>Please provide an abstract of 250 words containing the major keywords summarizing the article.</p>

        <p><strong>Keywords</strong></p>
        <p>Please provide four to six keywords.</p>

        <p><strong>Main Text</strong></p>
        <ul>
          <li>The main text should include the introduction, materials and methods, results, and discussion.</li>
          <li>The journal uses US spelling; however, authors may submit using either option, as spelling of accepted papers is converted during the production process.</li>         
        </ul>

        <p><strong>Methods and Materials</strong></p>
        <p>If a method or tool is introduced in the study, including software, questionnaires, and scales, the author should state the license this is available under and any requirement for permission for use. If an existing method or tool is used in the research, the authors are responsible for checking the license and obtaining the permission. If permission was required, a statement confirming permission should be included in the Methods and Materials section. </p>

        <p><strong>References</strong></p>
        <p>References should be prepared according to the Publication Manual of the American Psychological Association (6th edition). This means in text citations should follow the author-date method whereby the author's last name and the year of publication for the source should appear in the text, for example, (Jones, 1998). The complete reference list should appear alphabetically by name at the end of the paper.</p>
        <p>A sample of the most common entries in reference lists appears below. For more information about APA referencing style, please refer to the APA FAQ. Note that for journal articles, issue numbers are not included unless each issue in the volume begins with page one, and a DOI should be provided for all references where available.</p>

        <em>Journal article</em>
        <p>Beers, S. R. , & De Bellis, M. D. (2002). Neuropsychological function in children with maltreatment-related posttraumatic stress disorder. The American Journal of Psychiatry, 159, 483–486. doi:10.1176/appi.ajp.159.3.483</p>

        <em>Book</em>
        <p>Bradley-Johnson, S. (1994). Psychoeducational assessment of students who are visually impaired or blind: Infancy through high school (2nd ed.). Austin, TX: Pro-ed.</p>

        <em>Internet Document</em>
        <p>Norton, R. (2006, November 4). How to train a cat to operate a light switch [Video file]. Retrieved from http://www.youtube.com/watch?v=Vja83KLQXZs</p>

        <p><strong>Footnotes</strong></p>
        <p>Footnotes should be placed as a list at the end of the paper only, not at the foot of each page. They should be kept to a minimum. Keep footnotes brief; they should contain only short comments tangential to the main argument of the paper and should not include references. They should be numbered in the list and referred to in the text with consecutive, superscript Arabic numerals. </p>

        <p><strong>Tables</strong></p>
        <p>Tables should be self-contained and complement, not duplicate, information contained in the text. They should be supplied as editable files, not pasted as images. Legends should be concise but comprehensive – the table, legend, and footnotes must be understandable without reference to the text. All abbreviations must be defined in footnotes. Footnote symbols: †, ‡, §, ¶, should be used (in that order) and *, **, *** should be reserved for P-values. Statistical measures such as SD or SEM should be identified in the headings.</p>

        <p><strong>Figure Legends</strong></p>
        <p>Legends should be concise but comprehensive – the figure and its legend must be understandable without reference to the text. Include definitions of any symbols used and define/explain all abbreviations and units of measurement.</p>

        <p><strong>Figures</strong></p>
        <p>Although authors are encouraged to send the highest-quality figures possible, for peer-review purposes, a wide variety of formats, sizes, and resolutions are accepted.</p>
        <p>Click here for the basic figure requirements for figures submitted with manuscripts for initial peer review, as well as the more detailed post-acceptance figure requirements.</p>

        <p><strong>Color Figures</strong></p>
        <p>Figures submitted in color may be reproduced in colour online free of charge. Please note, however, that it is preferable that line figures (e.g. graphs and charts) are supplied in black and white so that they are legible if printed by a reader in black and white. </p>

        <p><strong>Additional Files</strong></p>

        <em><strong>Appendices</strong></em>
        <p>Appendices will be published after the references. For submission they should be supplied as separate files but referred to in the text.</p>

        <em><strong>Graphical Table of Contents</strong></em>
        <p>The journal’s table of contents will be presented in graphical form with a brief abstract. </p>

        <p>The table of contents entry must include the article title, the authors' names (with the corresponding author indicated by an asterisk), no more than 80 words or 3 sentences of text summarising the key findings presented in the paper and a figure that best represents the scope of the paper (see the section on abstract writing for more guidance). </p>
        <p>Table of contents entries should be submitted to Scholar One in one of the generic file formats and uploaded as ‘Supplementary material for review’ during the initial manuscript submission process. </p>
        <p>The image supplied should fit within the dimensions of 50mm x 60mm, and be fully legible at this size. </p>

        <em><strong>Supporting Information</strong></em>
        <p>Supporting information is information that is not essential to the article, but provides greater depth and background. It is hosted online and appears without editing or typesetting. It may include tables, figures, videos, datasets, etc.</p>
        <p>Note: if data, scripts, or other artefacts used to generate the analyses presented in the paper are available via a publicly available data repository, authors should include a reference to the location of the material within their paper.</p>

        <p><strong>Author Recommended Reviewers</strong></p>
        <p>In order to expedite independent peer review, authors are requested to suggest reviewers from an international range of countries with academic expertise in the subject area of their submission. There should be no more than one suggestion from a single country. Reviewers should be published authors of peer reviewed journal articles or books. Co-authors for the submission or from any other publication by the authors within the past 2 years as well as academics from the authors’ own institutions may not be recommended. Emails provided should be verifiable to an institutional website such as a University,  Research Institute or Commercial corporation. Note: Helpful suggestions can expedite peer review. </p>

        <em>Return to Guideline Sections</em>
      </div>

      <div class="sect" id="_6.EDITORIAL POLICIES AND ETHICAL CONSIDERATIONS">&nbsp; 6. EDITORIAL POLICIES AND ETHICAL CONSIDERATIONS</div>
      <div class="sect-desc">
        <p><strong>Peer Review and Acceptance</strong></p>
        <p>The acceptance criteria for all papers are the quality and originality of the research and its significance to journal readership. Papers will only be sent to review if the Editor-in-Chief determines that the paper meets the appropriate quality and relevance requirements. </p>

        <p><strong>Refer and Transfer Program </strong></p>
        <p>We believes that no valuable research should go unshared. If your manuscript is not accepted, you may receive a recommendation to transfer your manuscript to another suitable journal, either through a referral from the journal’s editor or through our Transfer Desk Assistant. </p>

        <p><strong>Guidelines on Publishing and Research Ethics in Journal Articles </strong></p>
        <p>Please review YSA's policies surrounding human studies, animal studies, clinical trial registration, biosecurity, and research reporting guidelines. <p>

        <p><strong>Data Sharing and Data Accessibility</strong></p>
        <p>The journal expects authors to share the data and other artefacts supporting the results in the paper by archiving it in an appropriate public repository. Authors must include a data accessibility statement, including a link to the repository they have used, in order that this statement can be published alongside their paper. </p>

        <p><strong>Human Studies and Subjects</strong></p>
        <p>For manuscripts reporting medical studies that involve human participants, a statement identifying the ethics committee that approved the study and confirmation that the study conforms to recognized standards is required, for example: Declaration of Helsinki; US Federal Policy for the Protection of Human Subjects; or European Medicines Agency Guidelines for Good Clinical Practice. It should also state clearly in the text that all persons gave their informed consent prior to their inclusion in the study.</p>

        <p>Patient anonymity should be preserved. Photographs need to be cropped sufficiently to prevent human subjects being recognized (or an eye bar should be used). Images and information from individual participants will only be published where the authors have obtained the individual's free prior informed consent. Authors do not need to provide a copy of the consent form to the publisher; however, in signing the author license to publish, authors are required to confirm that consent has been obtained. YSA has a standard patient consent form available for use.</p>

        <p><strong>Animal Studies</strong></p>
        <p>A statement indicating that the protocol and procedures employed were ethically reviewed and approved, as well as the name of the body giving approval, must be included in the Methods section of the manuscript. Authors are encouraged to adhere to animal research reporting standards, for example the ARRIVE guidelines for reporting study design and statistical analysis; experimental procedures; experimental animals and housing and husbandry. Authors should also state whether experiments were performed in accordance with relevant institutional and national guidelines for the care and use of laboratory animals:</p>

        <ul>
          <li>US authors should cite compliance with the US National Research Council's Guide for the Care and Use of Laboratory Animals, the US Public Health Service's Policy on Humane Care and Use of Laboratory Animals, and Guide for the Care and Use of Laboratory Animals.</li>
          <li>UK authors should conform to UK legislation under the Animals (Scientific Procedures) Act 1986 Amendment Regulations (SI 2012/3039).</li>
          <li>European authors outside the UK should conform to Directive 2010/63/EU.</li>
        </ul>

        <p><strong>Conflict of Interest</strong></p>
        <p>The journal requires that all authors disclose any potential sources of conflict of interest. Any interest or relationship, financial or otherwise that might be perceived as influencing an author's objectivity is considered a potential source of conflict of interest. These must be disclosed when directly relevant or directly related to the work that the authors describe in their manuscript. Potential sources of conflict of interest include, but are not limited to: patent or stock ownership, membership of a company board of directors, membership of an advisory board or committee for a company, and consultancy for or receipt of speaker's fees from a company. The existence of a conflict of interest does not preclude publication. If the authors have no conflict of interest to declare, they must also state this at submission. It is the responsibility of the corresponding author to review this policy with all authors and collectively to disclose with the submission ALL pertinent commercial and other relationships.</p>

        <p><strong>Funding</strong></p>
        <p>Authors should list all funding sources in the Acknowledgments section. Authors are responsible for the accuracy of their funder designation. </p>

        <p><strong>Authorship</strong></p>
        <p>The list of authors should accurately illustrate who contributed to the work and how. All those listed as authors should qualify for authorship according to the following criteria:</p>
        <p>1. Have made substantial contributions to conception and design, or acquisition of data, or analysis and interpretation of data; </p>

        <p>2. Been involved in drafting the manuscript or revising it critically for important intellectual content; </p>

        <p>3. Given final approval of the version to be published. Each author should have participated sufficiently in the work to take public responsibility for appropriate portions of the content; and </p>

        <p>4. Agreed to be accountable for all aspects of the work in ensuring that questions related to the accuracy or integrity of any part of the work are appropriately investigated and resolved. </p>

        <p>Contributions from anyone who does not meet the criteria for authorship should be listed, with permission from the contributor, in an Acknowledgments section (for example, to recognize contributions from people who provided technical help, collation of data, writing assistance, acquisition of funding, or a department chairperson who provided general support). Prior to submitting the article all authors should agree on the order in which their names will be listed in the manuscript.</p>

        <p>In accordance with YSA's Best Practice Guidelines on Research Integrity and Publishing Ethics and the Committee on Publication Ethics’ guidance, Food Science and Nutrition will allow authors to correct authorship on a submitted, accepted, or published article if a valid reason exists to do so. All authors – including those to be added or removed – must agree to any proposed change. To request a change to the author list, please complete the Request for Changes to a Journal Article Author List Form and contact either the journal’s editorial or production office, depending on the status of the article. Authorship changes will not be considered without a fully completed Author Change form.</p>

        <p><strong>Additional Authorship Options</strong></p>

        <p>Joint first or senior authorship: In the case of joint first authorship, a footnote should be added to the author listing, e.g. ‘X and Y should be considered joint first author’ or ‘X and Y should be considered joint senior author.’</p>

        <p><strong>Author Name Change Policy</strong></p>
        <p>In cases where authors wish to change their name following publication, YSA will update and republish the paper and redeliver the updated metadata to indexing services. Our editorial and production teams will use discretion in recognizing that name changes may be of a sensitive and private nature for various reasons including (but not limited to) alignment with gender identity, or as a result of marriage, divorce, or religious conversion. Accordingly, to protect the author’s privacy, we will not publish a correction notice to the paper, and we will not notify co-authors of the change. Authors should contact the journal’s Editorial Office with their name change request. </p>

      </div>

      <div class="sect" id="_7.AUTHOR LICENSING AND OPEN ACCESS FEES">&nbsp; 7. AUTHOR LICENSING AND OPEN ACCESS FEES</div>
      <div class="sect-desc">        
        <p>Authors of accepted papers pay an Article Publication Charge and their papers are published under a Creative Commons license. With Creative Commons licenses, the author retains copyright and the public is allowed to reuse the content. The author grants YSA a license to publish the article and identify as the original publisher. </p>
      </div>

      <div class="sect" id="_8.PUBLICATION PROCESS AFTER ACCEPTANCE">&nbsp; 8. PUBLICATION PROCESS AFTER ACCEPTANCE</div>
      <div class="sect-desc">   
        <p><strong>Accepted article received in production</strong></p>
        <p>When an accepted article is received by YSA’s production team, the corresponding author will receive an email asking them to login or register with YSA Author Services. The author will be asked to sign a publication license at this point.</p>

        <p><strong>Proofs</strong></p>
        <p>Authors will receive an e-mail notification with a link and instructions for accessing HTML page proofs online. Page proofs should be carefully proofread for any copyediting or typesetting errors. Online guidelines are provided within the system. No special software is required, all common browsers are supported. Authors should also make sure that any renumbered tables, figures, or references match text citations and that figure legends correspond with text citations and actual figures. Proofs must be returned within 48 hours of receipt of the email. Return of proofs via e-mail is possible in the event that the online system cannot be used or accessed. 

        <p><strong>Early View</strong></p>
        <p>The journal offers rapid speed to publication via YSA' Early View service. Early View (Online Version of Record) articles are published on YSA Online Library before inclusion in an issue. Note there may be a delay after corrections are received before the article appears online, as Editors also need to review proofs. Once the article is published on Early View, no further changes to the article are possible. The Early View article is fully citable and carries an online publication date and DOI for citations.</p>

        <em>Return to Guideline Sections</em> 
      </div>

      <div class="sect" id="_9.POST PUBLICATION">&nbsp; 9. POST PUBLICATION</div>
      <div class="sect-desc">   
        <p><strong>Access and sharing</strong></p>
        <p>When the article is published online:</p>
        <ul>
          <li>The author receives an email alert (if requested).</li>
          <li>As this is an open access journal, the author will have free, unlimited access to view and download their article online.</li>
        </ul>
      </div>

      <div class="sect" id="_10.JOURNAL CONTACT DETAILS">&nbsp; 10. JOURNAL CONTACT DETAILS</div>
      <div class="sect-desc">   
      
        <p>High School Student Submissions: info@ysassociation.org</p>
        <p>Undergraduate Student Submission: exp@ysassociation.org</p>
        <p>Editor: Y. Martin Lo, martin@ysassociation.org</p>
        <!-- <p>Editor: Dr. Tim, Biointellipro, tim@ysassociation.org</p> -->
        <!-- <p>Editor: Dr. Tom, Artificial intelligence, tom@ysassociation.org</p> -->
        <p>Author Guidelines updated 24 August 2024 </p>
      </div>

    </div>
  
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
  import Header from '../components/Header.vue'
  import Footer from "../components/Footer.vue";
 
  export default {
    name: "Home",
    data() {
      return {

      };
    },    
    components: {
      Footer,
      Header,
      // SectTurnBook,
    },

    mounted() {
    },

    methods: {
    }
  
  }

</script>


<style lang="less" scoped>
.container {
  // margin-bottom: 40px;
  padding-bottom: 0;
}
.panel{
  max-width: 1024px;
  margin: 10px auto;
  padding-left: 10px;
  text-align: left ;
  line-height: 1.5 ;
  .sect {      
    // font-size: px;
    font-weight: 700;
    color: #fff;
    background-color: #8dc641;
    // letter-spacing: 0px;
  }
  .sect-desc{
    text-align: left;
    line-height: 1.25 ;
    padding: 5px 2px;
    font-size: 14px;
    color:#333;
    // p::before{
    //   content: '•';
    //   margin-right: 5px;
    // }
  }
  > p{
    font-size: 14px;
    font-weight: 200;
  }
  > li{
    font-size: 12px;
    color: #123d80;
  }
  > li::marker{
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
  }
}

.txt-link{
  font-size: 14px;
}
.txt-red{
  color: red;
}

.main {
  color: #333;
  padding: 0 10%;
  // 中区域的左侧文字区
}



//显示时按钮样式
.el-button--primary { //需要更改的按钮类型
  background: #2836e0 !important;
  border-color: #2836e0 !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #005eff !important;
  border-color: #005eff !important;
  color: #FFF !important;
}

@media (max-width: 550px) {
  .panel{
    margin: 50px 10px !important;
    h1{
      font-size: 36px !important;
    }
  }
}
</style>
