<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <Header :headerData={} />

    <div class="panel">
      <div class="info">
        <H2>Editorial Team</H2>
        <H4>Please Note: This is only a partial list of our editorial team who supports YSA.</H4>
    
        <div class="box"
          v-for="(pic, i) in pic_list" :key="i"
          @click="clickShowDetail(i)"      
        >
          <div class="imgBx">
            <img :src="pic.src" alt=""/>
          </div>
          <div class="content">
            <h4>{{pic.name}} | {{pic.title}}</h4>
            <p>{{pic.desc}}</p>
          </div>
        </div>


        <el-row justify="center">
          <div class="contact-us">
            <h3>JOURNAL CONTACT LIST</h3>
            <h4>High School Student Submissions: info@ysassociation.org</h4>
            <p>Editor: Y. Martin Lo, Biointellipro, martin@ysassociation.org</p>
          </div>
        </el-row>

      </div>
    </div>
  
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from "../components/Footer.vue";
  // import Swiper JS
  // import Swiper from 'swiper';
  // // import Swiper styles
  // import 'swiper/css';
  // const swiper = new Swiper('.swiper', {
  //   // Optional parameters
  //   direction: 'vertical',
  //   loop: true,

  //   // If we need pagination
  //   pagination: {
  //     el: '.swiper-pagination',
  //   },

  //   // Navigation arrows
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },

  //   // And if we need scrollbar
  //   scrollbar: {
  //     el: '.swiper-scrollbar',
  //   },
  // });
  

  export default {
    name: "Home",
    data() {
      return {
        dialogVisible: false,
        currPic: '',
        currPicBx: '',
        name: '',
        desc: '',
        pic_list:[
          {
            id: 1,
            name: 'Dr. Y. Martin Lo',
            title: 'Founder of YSA',
            desc: `Tenured professor of University of Maryland (UMD), the Chief Scientist at the Reed Center for Ecosystem Reintegration. Key areas of research include: carbon sequestration, reduction of greenhouse gas emission, and biodiversity metrics. Dr. Lo helped many universities around the world to develop food safety programs after a long-standing career at University of Delaware and University of Maryland. He is a well-published food engineer known for bioconversion of agricultural byproducts and strategies to ensure food safety. He is the recipient of the Food Safety Research Award by the UN Human Health Organization (HHO) and twice the Presidential Silver Medals from the White House.`,
            desc_zh: '',
            src: require("../assets/img/scientist/Martin.jpg"),
            srcBx: require("../assets/img/scientist/Martin.jpg"),
          },
          {
            id: 2,
            name: 'Dr. Tim, Y.H. Wong',
            title: 'Director of YSA',
            desc: `Marine biologist. He dedicated to a variety of non-model marine organisms, ranging from foundational taxonomic groups such as sponges and corals to complex biological systems like bryozoans and sea squirts. Dr. Tim is regarded by his colleagues and students as a rigorous yet passionate teacher and marine biologist. He encourages students to cultivate an interest in Mother Nature by experiencing it firsthand rather than solely relying on memorizing textbook content.`,
            desc_zh: '香港科技大学获学士学位，2012获香港香港科技大学生物科学博士学位。他是台湾中央研究院生物多样性中心的访问学者，日本秋田县大学生物技术系的特聘助理教授。专注于海洋生物附着问题研究，致力于多种非模式海洋生物，从海绵和珊瑚等基础分类群，到苔藓虫和藤壶等复杂生物系统。他应邀在美国、日本、丹麦和德国介绍了他在藤壶生物学和防污策略开发方面的工作。Dr. Tim被他的同事和学生称为一位严格但热情的老师和海洋生物学家。他鼓励学生通过坐在教室里听枯燥的讲座来培养对大自然母亲的兴趣。他认为看到真实的东西比记住课本内容要好。',
            src: require("../assets/img/scientist/Tim.jpg"),
            srcBx: require("../assets/img/scientist/Tim_1.jpg"),
          },
          {
            id: 3,
            name: 'Dr. Tom, Song Xu',
            title: 'Director of YSA',
            desc: `Computer Scientist. He has extensive experience in AI edge computing and AIoT, and has interdisciplinary research collaborations in the areas of oceans, soil environment, sustainable development with Shenzhen University(SZU), University of Hong Kong (HKU), and University of Science and Technology (HKUST). He encourages students to think critically and be curious. Everyone is a scientist as long as they continue to learn and explore and discover the truth!`,
            desc_zh: '',
            src: require("../assets/img/scientist/Xu.jpg"),
            srcBx: require("../assets/img/scientist/Xu_1.jpg"),
          },
          {
            id: 4,
            name: 'Dr. Adam',
            title: 'Mathematican (MIT)',
            desc: `Mathworks Mathematics Fellowship, MIT, 2023 and Peterson Fellowship, MIT, 2022. Researched on Entropy and heat kernel bounds on a Ricci flow background at University of California, Berkeley, 2021`,
            desc_zh: '',
            src: require("../assets/img/scientist/Yiqi.jpg"),
            srcBx: require("../assets/img/scientist/Yiqi.jpg"),
          },
          {
            id: 5,
            name: 'Dr. Tang',
            title: 'Optoelectronic Physicist (PSU)',
            desc: 'Shenzhen Institutes of Advanced Technology of Chinese Academy of Sciences(SIAT). Worked in Steven Chu’s lab of National Laboratory of USA. Outstanding Winner(Shenzhen sect.) of China-US Young Maker Competition(CUYMC) 2021.',
            desc_zh: '获得宾夕法尼亚州立大学（PSU）博士学位，中国科学院深圳先进技术研究院进行博士后研究。曾在美国国家实验室朱棣文项目组工作。深圳市引进的海外人才，他是特殊光学成像技术的学术带头人，在计算机视觉人工智能辅助设计的新衍射光学，用以有限条件中提高显微镜性能的理论研究中有重点项目。2021年带队荣获得中美青年科学创客竞赛（CUYMC）全国二等奖，深圳赛区特等奖。',
            src: require("../assets/img/scientist/Tang.jpg"),
            srcBx: require("../assets/img/scientist/Tang_1.jpg"),
          },
          {
            id: 6,
            name: 'Dr. Zhang',
            title: 'Biologist (HKUST)',
            desc: 'Life Sciences and Oceanography of Shenzhen University, Shenzhen Science and Technology Projects (Government Special Funding), and Shenzhen Oversea High-level Scientific Research Projects.',
            desc_zh: '香港科技大学生物系（海洋生态与生物技术方向），并于同年在香港科技大学海洋污染国家重点实验室进行博士后研究。博士和博士后期间参与国家自然科学基金委、香港政府RGC、中国大洋协会、中国科学院三亚深海科学与工程研究所、沙特阿拉伯国王科技大学等多项课题研究。现于深圳大学生命与海洋科学学院从事教学与科研工作。主持6个重点科研专项，包括广东省自然科学基金、广东省教育厅重点平台及科研项目、深圳市海外高层次人才创新创业项目、深圳市科技计划项目（战略性新兴和未来产业专项资金）、深圳市海外高端人才科研项目。深圳市南山区领航人才。',
            src: require("../assets/img/scientist/Zhang.jpg"),
            srcBx: require("../assets/img/scientist/Zhang_1.jpg"),
          },
          {
            id: 7,
            name: 'Dr. Chen',
            title: 'Environmental Biologist (UA)',
            desc: 'Ph.D degree in Ecology (University of Alberta, Canada) and Master degree in Environmental Sciences (Institute of Hydrobiology, Chinese Academy of Sciences). Research on freshwater and marine ecology, dinoflagellate biology and phytoplankton-bacteria. ',
            desc_zh: '加拿大阿尔伯塔环境生态学博士学位，中国科学院水生生物研究所硕士学位。研究领域：淡水和海洋生态学、甲藻生物学和有害藻华（HAB）中的浮游植物-细菌相互作用。研究并关注浮游生物在海洋生态系统中的影响，为政府部门预测生态分布和建立相关的生态学基础模型提供重要决策依据。',
            src: require("../assets/img/scientist/Chen.jpg"),
            srcBx: require("../assets/img/scientist/Chen_1.jpg"),
          },
          // {
          //   id: 5,
          //   name: 'Dr. Robey Guo',
          //   title: 'AI Scientist (SIAT)',
          //   desc: 'Graduated from Shenzhen Institutes of Advanced Technology of Chinese Academy of Sciences(SIAT), main research on embedded machine vision,  deep learning algorithm and FPGA programming.',
          //   desc_zh: '毕业于西南交通大学，现在中国科学院大学（UCAS）进行人工智能方向研究。主要研究课题是：嵌入式计算机视觉、深度学习算法和FPGA编程。在利用人工智能深度学习网络探索海洋物种的交叉学科方向进行有若干重大项目。他的研究兴趣包括光学成像技术及其在水下机器视觉观测方法。在2021年中美青年创客大赛（CUYMC）中有重大技术贡献。',
          //   src: require("../assets/img/scientist/Guo.jpg"),
          //   srcBx: require("../assets/img/scientist/Guo_1.jpg"),
          // },
          // {
          //   id: 6,
          //   name: 'Dr. Wanting Cheng',
          //   title: 'Physical Oceanographer (UT)',
          //   desc: 'Dr. degree of Tokyo University of Marine Science and Technology. Her research direction is to use artificial intelligence technology to study marine biology for predicting ecological distribution and establishing relevant ecological models.',
          //   desc_zh: '',
          //   src: require("../assets/img/scientist/Cheng.jpg"),
          //   srcBx: require("../assets/img/scientist/Cheng_1.jpg"),
          // },
          // {
          //   id: 7,
          //   name: 'Dr. Frank Yang',
          //   title: 'AI Scientist (SIAT)',
          //   desc: 'Graduated from Shenzhen Institutes of Advanced Technology of Chinese Academy of Sciences(SIAT). His research projects include deployment of Edge AI in various hardware devices, intelligent forecasting based on deep learning in critical application scenarios  .',
          //   desc_zh: '毕业于南京理工大学，现在中国科学院大学（UCAS）进行AI机器视觉和图像分析领域的研究。他的研究项目包括：如何在通用物理设备中部署边缘AI计算（Edge AI），在海洋生物学等关键应用场景中部署基于深度学习的智能预测算法，同时还研究在物体运动检测领域的智能虚拟助手，以及嵌入式系统中的深度学习网络的关键算法和流程。',
          //   src: require("../assets/img/scientist/Yang.jpg"),
          //   srcBx: require("../assets/img/scientist/Yang_1.jpg"),
          // },
          {
            id: 8,
            name: 'Dr. Jeremy Zhang',
            title: 'AI Scientist (UniMelb)',
            desc: `Human Computer Interaction Dept. of the University of Melbourne, Australia. Research interests in LLM and Human-Al Interaction, especially utilizing multimodal learning and auto machine learning to 
                  explore better user experience and efficient andeffective ways to improve our productivity, and AIGC for Education.`,
            desc_zh: '',
            src: require("../assets/img/scientist/JerrmyZhang.jpg"),
            srcBx: require("../assets/img/scientist/JerrmyZhang.jpg"),
          },
        ]
      };
    },    
    components: {
      Footer,
      Header,
      // SectTurnBook,
    },

    mounted() {
    },

    methods: {
    }
  
  }

</script>


<style lang="less" scoped>
.container {
  // margin-bottom: 40px;
  padding-bottom: 0;
}
.panel{
  max-width: 1024px;
  margin: 100px auto;
  padding-left: 10px;
  text-align: left ;
  line-height: 1.75 ;
  .main-title {      
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 0px;
  }
  > p{
    font-size: 19px;
    font-weight: 200;
  }
}
.sect-desc{
  text-align: left;
  line-height: 1.75 ;
  padding: 5px 20px;
  font-size: 19px;
  color:#333;
  p::before{
    content: '•';
    margin-right: 5px;
  }
}

.box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 40px;
    // padding: 10px 35px;
    .imgBx {
      width: 250px;
      // height: 300px;
      img{
        width : 100%;
        // border: 1px solid red;
        border-radius:50%;
        border-style: 3px solid;
        border-color: darkgray; 
        box-shadow: 0 5px 20px rgba(0,0,0,0.2);
      }
      p{
        font-size: 12px;
      }
    }
    .content{
      width: 65%;
      margin-left: 20px;
      text-align: left;
      h3{
        font-size: 12px;
        font-weight: 800;
        color:#333;
      }
    }
  }

.txt-link{
  font-size: 14px;
}

.main {
  color: #333;
  padding: 0 10%;
  // 中区域的左侧文字区
}

.sect-title {
  max-width: 580px;
  text-align: center;
  margin: 24px auto;
  font-size: 26px;
  font-weight: bold;
}

.contact-us {
  line-height: 1.0 ;
}

//显示时按钮样式
.el-button--primary { //需要更改的按钮类型
  background: #2836e0 !important;
  border-color: #2836e0 !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #005eff !important;
  border-color: #005eff !important;
  color: #FFF !important;
}

@media (max-width: 550px) {
  .panel{
    margin: 50px 10px !important;
    h1{
      font-size: 36px !important;
    }
  }
}
</style>
